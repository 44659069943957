body{
  margin: 0;
}

a{
  color: #5D2D24;
}

.btn-primary{
  background: linear-gradient(to right, #5D2D24, #dab467);
  border-color: #5D2D24;
}


header{
  position: fixed;
  z-index: 999;
  width: 100%;
}

.bg-gold h1{
  
 text-shadow: #634f2c 1px 0 10px;
  color: rgb(210, 183, 113);
  text-transform: uppercase;
  position: relative;
}


h1:after {
  background: none;
  content: attr(data-heading);
  left: 0;
top: 0;
  z-index: -1;
  position: absolute;
  text-shadow: 
  -1px 0 1px #c6bb9f, 
  0 1px 1px #c6bb9f, 
  5px 5px 10px rgba(0, 0, 0, 0.4),
  -5px -5px 10px rgba(0, 0, 0, 0.4);
}

.logo-container{
  
  display: flex;
  position: relative;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items:start;
  grid-gap: 10px;
  gap: 10px;
  padding: 10px;
  z-index: 999;
  width: 100%;
}

.logo-img {
  text-align: center;
}

.logo-img  .logo-tooltip {
  position: absolute;
  width: 100%;
  top: 89%;
  left: 0;
  padding: 5px 10px;
  background: #fff;
  border-radius: 5px;
  border: 2px solid #f17ed4;
  visibility: hidden;
}



.logo-img:hover  .logo-tooltip, 
.logo-img:focus  .logo-tooltip {
  visibility: visible;
}


#mapbox{
  position: relative;
  height: 100vh;
  width: 100%;

  background: url('/swami-bg.jpg');
  background-size: cover;
  background-position: center center;
}
.map-overlay {
  position: absolute;
  background: rgba(0,0,0,0.3);
  width: 100%;
  height: 100%;
  filter: blur(5px);
}
.popup-box{
  max-width: 360px;
  min-width: 360px;
  position: absolute;
  bottom: 36px;
  left: 9px;
  z-index: 9;
}
#mapbox .mapbox-map{
  position: absolute;
  width: 100%;
  height: 100%;
}

.popup-box::before{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  filter: blur(3px);
  border-radius: 15px;
  background: rgba(255,255,255,1);
}

.popup-box .popup-content{
  position: relative;
  padding: 15px;
  border-radius: 15px;
}

.popup-box .popup-content .heading{
  margin: 0;
}

.popup-box .popup-content .text{
  text-align: left;
}

.countdown{
  background: #fff;
  padding: 10px 5px;
  border-radius: 15px;
}
.bg-theme{
    background: linear-gradient(to right, #5D2D24, #dab467);
  color: #fff;
}
.text-theme{
  color: #5D2D24;
}
.btn-theme{
    background: linear-gradient(to right, #5D2D24, #dab467);
  color: #fff;
}


.btn-theme:hover{
  background-color: #91224b;
  color: #f2fff2;
}

.mapboxgl-popup
{
  top: -11px !important;
  width: 99%;
  margin: auto;
}
.mapboxgl-popup::before{
  
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  filter: blur(2px);
  bottom: 5px;
  border-radius: 15px;
  transform: scale(1.05);
  background: rgb(97, 15, 45, .3);
}
.mapboxgl-popup-content{
  padding: 10px 27px 10px 10px !important;
  border-radius: 10px !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }

  button.mapboxgl-popup-close-button {
    background: #5D2D24;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    padding: 2px 6px;
    /* bottom: 114%; */
}
.mapboxgl-popup-content h6{
  font-size: 12px;
}
button.mapboxgl-popup-close-button:hover{
  background: #fff;
  color: #5D2D24;
}
.counter{
  color: #fff;
  background: #5D2D24;
  padding: 5px 10px;
  border-radius: 10px;

}

.bg-white-alpha{
  background-color: rgb(255 255 255 / 20%);
}

.bg-theme-alpha{
  background-color: #5D2D24cc;
  color: #fff;
}
.countdown .flip-countdown.theme-light {
  --text-color: #fff;
  --text-bottom-color: #fff;
  --background-color: #5D2D24;
  --background-bottom-color: #c87d1c;
}
.text-danger{
  color: #5D2D24 !important;
}
.countdown-box{
 
  max-width: 100%;
  position: absolute;
  bottom: 89%;
  right: 10px;
  z-index: 9;
  border-radius: 15px;
}

.countdown-heading{
  padding: 5px 10px;
  
  margin-bottom: 5px;
  border-radius: 5px;

  justify-content: space-between;
  align-items: center;
  display: flex;
}


ul.countdown {
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
  gap: 10px;
  justify-content: center;
}


ul.countdown li{
  
  display: flex;
  text-align: center;
  flex-direction: column;
  background-color: #fff;
  color: #5D2D24;
  border-radius: 15px;
  padding: 10px;
  border: 2px solid #5D2D24;
  width: 81px;
}



ul.countdown li span{
  font-size: 12px;
}

.popup-box .popup-close{
  position: absolute;
  top: 0;
  color: #5D2D24;
  right: 0;
  z-index: 18;
}

.comment-box{
  max-width: 100%;
  min-width: 360px;
  position: absolute;
  bottom: 18px;
  right: 9px;
  z-index: 9;
}

.comment-box::before{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  filter: blur(2px);
  border-radius: 15px;
  background: rgba(255,255,255,.9);
}

.comment-box .comment-content{
  position: relative;
  padding: 15px;
  border-radius: 15px;
  background-color: #fff;
}

.comment-box .comment-content .heading{
  margin: 0;
}

.comment-box .comment-content .text{
  text-align: right;
}

.profile{
  float: left;
  border-radius: 50%;
  overflow: hidden;
}

.map-profile{

  border-radius: 50%;
  overflow: hidden;
}

.profile img{
  max-width: 45px;
}

.marker{
  cursor: pointer;
}

.checkbox-lg .form-check-input{
  top: .8rem;
  scale: 1.4;
  margin-right: 0.7rem;
  }
 
 .checkbox-lg .form-check-label {
  padding-top: 13px;
  font-weight: bold;
  }
 
 .checkbox-xl .form-check-input {
  top: 1.2rem;
  scale: 1.7;
  margin-right: 0.8rem;
  border: 2px solid #5D2D24;
  }
  
  .msgbox {
    max-height: 54vh;
    overflow: auto;
    margin: 9px 0;
    display: flex;
    flex-direction: column-reverse;
    word-break: break-word;
}
.msgbox .msg{
  
  font-size: 12px;
}
.msgbox .time{
  font-size: 12px;
  margin-bottom: 3px;
}
.text-bubble{
  color: #2e4682;
  padding: 0;
}
.alert-bubble, .alert-reply {
  color: #fff;
  padding: 5px 10px;
  width: auto;
  display: inline-block;
  border-radius: 10px;
  transition: .5s all;
}
.alert-bubble {
  background-color: #def3ff;
  color: #2e4682;
}

.alert-bubble:hover, .alert-bubble:focus{
  background-color: #fff5de;
  transition: .5s all;
}

.alert-bubble.selected {
  background-color: #f6d3e0;
  transition: .5s all;
}

.comment-box li.list-group-item {
  padding: 0 5px;
  background: none;
  border: none;
}

.flip-countdown.size-small .flip-countdown-piece .flip-countdown-title{
  font-size: 10px !important;
  color: #5D2D24;
  font-weight: bold;
  -webkit-text-stroke-width: .2px;
  -webkit-text-stroke-color: #fff;
}

.mapboxgl-user-location-accuracy-circle{
  display: none;
}

#scene {
	position: fixed;
	top: 0%;
	left: 50%;
	width: 100%;
	height: 100vh;
  z-index: 99;
	overflow: hidden;
	margin-top: 0%;
	margin-left: -50%;
  
	box-shadow: 0 0 0 2px white inset;
}
#curtain {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: transparent;
  position: relative;
}

#curtain canvas{
  display: block;
  background: transparent;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#curtain .canvas-container{
  
  width: 100%;
  position: absolute;
  height: 100%;
}
#curtain .contents h1 {
    z-index: 9;
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    color: #fff;
    font-family: "Source Sans Pro";
    font-size: 72px;
    font-weight: 900;
    user-select: none;
}

#curtain .left,
#curtain .right {
	position: absolute;
	top: 0;
	width: 50%;
	height: 100%;
	filter: brightness(180%);
	background-image: url("/curtain.png");
	background-size: cover;
	background-repeat: no-repeat;
}

#curtain .left {
	left: 0;
	transform-origin: top right;
}
#curtain .right {
	left: 50%;
	transform-origin: top left;
}

.ground {
	position: absolute;
	left: 50%;
	top: 133%;
	width: 10000px;
	height: 10000px;
	margin-left: -5000px;
	border-radius: 100%;
	box-shadow: 0 0 100px 100px white;
}

#curtain .contents {
  position: absolute;
  height: 100%;
  background-color: rgba(0,0,0,.7);
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 10em;
  color: white;
  transform: scale(0.75);
  opacity: 0;
}

/* **********
	opening
********** */

#scene.expand {
	width: 140%;
	left: -20%;
	margin-left: 0;
  
	box-shadow: 0 0 0 0 white inset;
	animation-fill-mode: forwards;
	animation-name: expand-scene-horizontaly, expand-scene-verticaly;
	animation-duration: 2.5s, 1.5s;
	animation-timing-function: ease-in-out, ease-in-out;
	animation-delay: 0s, 2.5s;
	animation-iteration-count: 1, 1;
	animation-direction: normal, normal;
}

#curtain.open .left,
#curtain.open .right {
	filter: brightness(100%);
}
#curtain.open .left {
	animation-fill-mode: forwards;
	animation-name: curtain-opening, left-curtain-opening;
	animation-duration: 2s, 4s;
	animation-timing-function: ease-in-out, ease-in-out;
	animation-delay: 0s, 0s;
	animation-iteration-count: 1, 1;
	animation-direction: normal, normal;
}
#curtain.open .right {
	animation-fill-mode: forwards;
	animation-name: curtain-opening, right-curtain-opening;
	animation-duration: 2s, 4s;
	animation-timing-function: ease-in-out, ease-in-out;
	animation-delay: 0s, 0s;
	animation-iteration-count: 1, 1;
	animation-direction: normal, normal;
}

#scene.expand .ground {
	animation-fill-mode: forwards;
	animation-name: ground-rising;
	animation-duration: 6s;
	animation-timing-function: ease-out;
	animation-delay: 0s;
	animation-iteration-count: 1;
	animation-direction: normal;
}

#scene.expand .contents {
	animation-fill-mode: forwards;
	animation-name: text-zoom, text-fade-in;
	animation-duration: 5s, 1s, 1s;
	animation-timing-function: ease-out, ease-in-out, ease-in-out;
	animation-delay: 3s, 3s, 0s;
	animation-iteration-count: 1, 1, infinite;
	animation-direction: normal, normal, alternate;
}

.fade-out {
	animation-fill-mode: forwards;
	animation-name: fade-out;
	animation-duration: 1s;
	animation-timing-function: ease-in;
	animation-delay: 0s;
	animation-iteration-count: 1;
	animation-direction: normal;
}

/* **********
	animations
********** */

@keyframes expand-scene-horizontaly { /* 2.5
	s */
	from { 	
		width: 1200px;
		left: 50%;
		margin-left: -600px;
		/*background-color: rgb(0,0,0);*/
		box-shadow: 0 0 0 2px white inset;
	}
	to {
		width: 140%;
		left: -20%;
		margin-left: 0;
		/*background-color: rgb(32,32,32);*/
		box-shadow: 0 0 0 0 white inset;
	}
}

@keyframes expand-scene-verticaly { /* 1.5s */
	from { 	
		top: 50%;
		height: 600px;
		margin-top: -300px;
	}
	to {
		top: 0;
		height: 100%;
		margin-top: 0;
	}
}

@keyframes curtain-opening { /* 2s */
	from { filter: brightness(180%); }
	to { filter: brightness(100%); }
}

@keyframes left-curtain-opening { /* 4s */ 
	from { transform: translate(0) rotate(0) scale(1,1); }
	to { transform: translate(-100%) rotate(20deg) scale(0,2); }
}

@keyframes right-curtain-opening { /* 4s */
	from { transform: translate(0) rotate(0) scale(1,1); }
	to { transform: translate(100%) rotate(-20deg) scale(0,2); }
}

@keyframes ground-rising {
	from { top: 133%; }
	to { top: 105%; }
}

@keyframes text-zoom {
	from { transform: scale(0.75); }
	to { transform: scale(1); }
}

@keyframes text-fade-in {
	from { opacity: 0; }
	to { opacity: 1; }
}

@keyframes text-glowing {
	from { text-shadow: 0 0 3px #634f2c, 0 0 6px #634f2c, 0 0 9px #634f2c;  }
	to { text-shadow: 0 0 3px #634f2c, 0 0 6px white, 0 0 9px #cfc09f; }
}

@keyframes fade-out {
	from { color: black; opacity: 1; }
	to { color: white; opacity: 0; }
}

.pin-img{
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}


.pin-img img{
  position: relative;
  z-index: 1;
}

.pin-img::after{
  content: "";
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(255 214 2 / 20%);
  animation-fill-mode: forwards;
	animation-name: img-glowing;
	animation-duration: 5s, 1s, 1s;
	animation-timing-function: ease-out, ease-in-out, ease-in-out;
	animation-delay: 3s, 3s, 0s;
	animation-iteration-count: 1, 1, infinite;
	animation-direction: normal, normal, alternate;
}

@keyframes img-glowing {
	from { box-shadow: 0 0 10px rgb(255 214 2 / 20%), 0 0 20px rgb(255 214 2 / 20%), 0 0 30px rgb(255 214 2 / 20%);  }
	to { box-shadow: 0 0 10px rgb(255 214 2 / 20%), 0 0 20px white, 0 0 30px dodgerblue; }
}

@media screen and (max-width:800px) {
  #curtain .contents{
    padding: 150px 0 0px 0;
    z-index: 9;
  }
  #curtain .contents h1 {
    font-size: 27px;
    
  }
  .logo-container{
    flex-direction: column-reverse;
    justify-content: center;
    gap: 0;
  }
  .countdown-container{
    margin: auto;
  }

  .countdown-container h5{
    font-size: 14px;
  }
  .comment-box{
    width: 99%;
    left: 0;
    margin: 10px 5px;
    bottom: 36px;
  }
  .popup-box {
    z-index: 99;
    background: #fff;
    bottom: 0px;
    left: 0;
    margin: 0;
    max-width: 100%;
    width: 100%;
    padding-bottom: 36px;
}
  .countdown-box{
    
    width: 100%;
    right: 3px;
    margin: auto;
  }

  .logo-img .logo-tooltip.w-80 {
      top: 108px;
      width: 100% !important;
  }
  .flip-countdown.size-small .flip-countdown-piece .flip-countdown-title {
      font-size: 9px !important;
  }
  .flip-countdown.size-small .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec{
    font-size: 27px !important;
  }
  .msgbox{
      max-height: 45vh;
  }
  .msgbox.opened{
    max-height: 21vh;
  }
  .mapboxgl-ctrl-bottom-left{
    bottom: 20%!important;
  }
  a.mapboxgl-ctrl-logo{
    display: none !important;
  }
}